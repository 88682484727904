import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 70%;
  fill: ${(props) => (props.color ? '#213c83' : '#fff')};
  transition: fill 0.3s ease-in-out;
  /* &:hover {
    fill: var(--verde);
  } */
`;
//{ color }
const MotoIcon = () => (
  <Svg color id="prefix__Capa_1" data-name="Capa 1" viewBox="0 0 132.21 84.63">
    <path
      className="prefix__cls-1"
      d="M578.12 2271.17c-.33 1.65-.54 3.34-1 4.94a23.78 23.78 0 11-42.18-20.54 35.81 35.81 0 014-4.28c.36-.36.45-.57.22-1-1-2.13-2-4.28-3.11-6.54-.57.44-1.14.84-1.66 1.3a22.83 22.83 0 00-7.67 14.61 33.08 33.08 0 000 9 5.32 5.32 0 01-2.28 5.3 16.37 16.37 0 01-5.88 2.56 38 38 0 01-14.36.63c-2.16-.26-4.29-.71-6.42-1.07.21-1.46.47-2.76.57-4.07a29 29 0 00-30-31.46 24.28 24.28 0 00-8.72 1.82 10.75 10.75 0 01-5.73.64 13.41 13.41 0 01-6.45-2.45 13.93 13.93 0 01-1.59-1.66v-1.77c1.73-4.44 5.3-6.75 9.57-8.22a26.2 26.2 0 018.07-1.47c4.15-.05 8.31 0 12.46.17a10.09 10.09 0 018.8 5.49 4.81 4.81 0 002.21 2.07c4.47 2.09 9.23 2.6 14.05 2.7a22.49 22.49 0 013.82-7.15 16.8 16.8 0 019.91-5.3 53.77 53.77 0 017.28-.74 5 5 0 003.88-1.89 8.46 8.46 0 001.76-3.83 1.46 1.46 0 000-.72c-.38-1.18-.8-2.36-1.19-3.54-.13-.4-.36-.49-.78-.42a17 17 0 00-9.18 4.26 2.6 2.6 0 01-3.86-.06 2.64 2.64 0 01.27-3.81 21.09 21.09 0 0110.89-5.41c1.15-.23 2.32-.34 3.49-.46a2.89 2.89 0 013.08 1.77 47.22 47.22 0 013.32 9.39c1 3.66 1.86 7.36 3 11a99.9 99.9 0 005.62 13.93l1.65 3.28-4.58 2.87 5.19 9.25 4.31-2.9.53.78c1.84 2.59 3.64 5.22 5.55 7.76 1.22 1.62 2.62 3.1 3.91 4.67a2.61 2.61 0 11-4.06 3.27 83.53 83.53 0 01-9.2-12.06c-.28-.45-.57-.89-.88-1.36-.16.15-.25.22-.33.31a13.35 13.35 0 00-1.73 15.31 12.63 12.63 0 0011.73 6.75c5.34-.08 9.43-2.54 11.73-7.43a12.62 12.62 0 00-1.57-14 12.43 12.43 0 00-12.75-4.65c-.71.13-1.4.41-2.12.57-.2.05-.57 0-.65-.16-1.72-3-3.41-6-5.1-9a50 50 0 016-1.88 23.55 23.55 0 0128 20.31 6 6 0 00.16.71z"
      transform="translate(-445.9 -2208.74)"
    />
    <path
      className="prefix__cls-1"
      d="M445.9 2267.5c.2-1.05.36-2.12.59-3.16a23.72 23.72 0 0137.23-13.94 23.42 23.42 0 019.43 15.85c0 .19 0 .38.05.66h-10.56a13.3 13.3 0 00-14.24-10.52 13.21 13.21 0 000 26.3 13.26 13.26 0 0014.24-10.51h10.6a20.22 20.22 0 01-1.56 6.24c-3.89 8.82-10.59 13.91-20.22 14.82a23.54 23.54 0 01-24.5-16.79c-.47-1.64-.7-3.34-1-5zm93.34-47.4a20.26 20.26 0 0110.13-.28c3.26.88 4.68 2.72 4.9 6.38a39.79 39.79 0 01-.07 5.35c-.25 3.13-1.7 4.92-4.72 5.71a33.39 33.39 0 01-4.49.57c-.53.06-.59-.35-.7-.68-1.17-3.81-2.35-7.62-3.49-11.44-.55-1.82-1.02-3.71-1.56-5.61z"
      transform="translate(-445.9 -2208.74)"
    />
    <path
      className="prefix__cls-1"
      d="M482.62 2266.91a10.46 10.46 0 010 5.3h-4.85a.68.68 0 00-.77.47 7.94 7.94 0 01-15.23-3.73 7.75 7.75 0 016.3-7.16 7.83 7.83 0 018.77 4.34 1.16 1.16 0 001.3.81h4.48z"
      transform="translate(-445.9 -2208.74)"
    />
  </Svg>
);

export default MotoIcon;
