import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from "styled-components";
import Loader from 'react-loader-spinner';
import { Redirect, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import SwissMedical from '../../assets/sm_seguros.png';
import Coverit from '../../assets/logo-coverit.png';
import { setInspeccionData } from '../../actions';
import { useAlert } from 'react-alert';
import Inspeccion from './Inspeccion';

const InspeccionFinalizada = () => {
  var alert = useAlert();
  const dispatch = useDispatch();
  const { id, prev } = useParams();
  const [loading, setLoading] = useState(false);
  const [cotizacion, setCotizacion] = useState({});
  const [cliente, setCliente] = useState({});
  const [solicitud, setSolicitud] = useState({});
  const [cobertura, setCobertura] = useState({});
  const [categoria, setCategoria] = useState({});
  const inspeccion = useSelector((state) => state.inspeccion);

  const [emitir, setEmitir] = useState(false);

  const token = useSelector((state) => state.token);
  const BASE_URL = process.env.REACT_APP_API_URL;
  const handleGetArchivo = () => {
    fetch(`${BASE_URL}/obtener-certificado?cotizacion_id=${id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow',
      fileCache: true,
      dataType: 'blob'
    })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `FileName.pdf`,
      );
  
      // Append to html link element page
      document.body.appendChild(link);
  
      // Start download
      link.click();
  
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
    .catch((error) => console.log('error', error));
  }

  useEffect(() => {
    setLoading(true);
    if (token) {
      fetch(`${BASE_URL}/cotizacion?cotizacion_id=${id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow',
      })
        .then((response) => response.json())
        .then((result) => {
          setLoading(!result.status);
          if(result.status) {
            setCotizacion(result.cotizacion);
            setCliente(result.cliente);
            setSolicitud(result.solicitud);
            setCategoria(result.categoria);
            setCobertura(result.cobertura);
          }
        })
        .catch((error) => console.log('error', error));
    }
  }, [token]);

  return (
        <>
          <Container>
            {!inspeccion.emitir ? (
              <>
                <LogoCoveritImg
                  src={Coverit}
                  alt="Coverit"
                />
                <Title>
                  {cliente.nombre}
                </Title>
                {solicitud.okm === 1 && solicitud.tipo === 'vehiculo' ? (
                <Text>
                  El Certificado de No Rodamiento de tu vehículo 0km fue enviado correctamente. Su recepción no implica conformidad.
                </Text>
              ):(solicitud.tipo === 'vehiculo' ? (
                <Text>
                  Las fotos de tu vehículo fueron enviadas correctamente. Su recepción no implica conformidad.
                </Text>
              ):(
                <Text>
                  La foto de tu vehículo fue enviada correctamente. Su recepción no implica conformidad.
                </Text>
              )
              )}
                <Text>
                  Tu póliza digital con cobertura <b>{categoria.nombre}</b> ya se encuentra en proceso de emisión, dentro de las próximas 24 horas
                  vas a recibirla en tu casilla de correo electrónico. Si a lo largo del día no la recibiste en el correo que nos facilitaste,
                  por favor contactanos.
                </Text>
              </>
            ) : (
            <>
              <Title>
                {cliente.nombre}
                {', '}
                <TitleMod>Bienvenido a Coverit!</TitleMod>
              </Title>
              {solicitud.okm === 1 && solicitud.tipo === 'vehiculo' ? (
                <Text>
                  El Certificado de No Rodamiento de tu vehículo 0km fue enviado correctamente. Su recepción no implica conformidad.
                </Text>
              ):(solicitud.tipo === 'vehiculo' ? (
                <Text>
                  Las fotos de tu vehículo fueron enviadas correctamente. Su recepción no implica conformidad.
                </Text>
              ):(
                <Text>
                  La foto de tu vehículo fue enviada correctamente. Su recepción no implica conformidad.
                </Text>
              )
              )}
              <Text>
                Tu póliza digital con cobertura <b>{categoria.nombre}</b> ya se encuentra en proceso de emisión, dentro de las próximas 24 horas
                vas a recibirla en tu casilla de correo electrónico. Si a lo largo del día no la recibiste en el correo que nos facilitaste,
                por favor contactanos.
              </Text>

              <CoberturaContainer>
                <LogoImg src={solicitud.poliza_interface ? require('../../assets/'+solicitud.poliza_interface+'_seguros.png') : SwissMedical} alt="Swiss Medical Seguros" />

                <Cobertura>
                  <CoberturaNombre>Nro</CoberturaNombre>
                  <CoberturaNombre>{solicitud.poliza_id}</CoberturaNombre>
                </Cobertura>
              </CoberturaContainer>

              <BtnContinue onClick={handleGetArchivo}>
                <FontAwesomeIcon icon={faDownload} />
                Descargar certificado
              </BtnContinue>
            </>
          )}
        </Container>
    </>
  );
}

export default InspeccionFinalizada;

/**
 * STYLES
 */


const Container = styled.div`
padding: 30px 15px;
background: var(--verde);
min-height: calc(100vh);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

const Title = styled.p`
margin-bottom: 32px;
font-weight: 300;
color: var(--azul);
text-align: center;
font-size: 28px;
`;

const TextContent = styled.p`
  color: #fff;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 20px;
`;

const TitleMod = styled.span`
  color: #fff;
  display: block;
`;

const Text = styled.p`
  color: #fff;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 16px;
  font-weight: 300;
  text-align: justify;
`;

const BtnAsistencia = styled.button`
  color: var(--verde);
  border: none;
  border: 2px solid var(--verde);
  padding: 10px 20px;
  text-align: center;
  margin-top: 24px;
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 32px;
  background: none;
`;
const BtnContinue = styled.div`
  cursor: pointer;
  background: var(--verde);
  border: none;
  padding: 10px 20px;
  text-align: center;
  border: 2px solid var(--verde);
  display: block;
  width: 100%;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;

  & svg {
    margin-right: 10px;
  }
`;


const CoberturaContainer = styled.div`
  margin: 0 0 24px;
  border: 1px solid var(--gris);
  padding: 10px 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Cobertura = styled.div`
  text-align: center;
`;

const CoberturaNombre = styled.p`
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 5px;
`;

const CoberturaBtnInfo = styled.button`
  border: none;
  background: #909ec2;
  color: #fff;
  display: block;
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
`;

const LogoImg = styled.img`
  width: 40%;
`;

const Link = styled.a`
  text-align: center;
  margin-top: 15px;
`

const LogoCoveritImg = styled.img`
  padding: 2%;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #fff;
  width: 30% !important;
`;