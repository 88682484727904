import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { setInspeccionData } from '../../actions';
import {isMobile} from 'react-device-detect';
import { useAlert } from 'react-alert'

const Inspeccion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, prev } = useParams();
  const [cotizacion, setCotizacion] = useState({});
  const [loading, setLoading] = useState(false);
  const [emitir, setEmitir] = useState(false);
  const alert = useAlert()

  useEffect(() => {
    if (prev) {
      setEmitir(true);
    } else {
      setEmitir(false);
    }
  }, []);

  const token = useSelector((state) => state.token);
  const BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setLoading(true);
    if (token) {
      fetch(`${BASE_URL}/cotizacion?cotizacion_id=${id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow',
      })
        .then((response) => response.json())
        .then((result) => {
          setLoading(false);
          if(result.status) {
            setCotizacion(result);
          } else {
            alert.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false)
          console.log('error', error)
        });
    }
  }, [token]);

  useEffect(() => {
    dispatch(
      setInspeccionData({
        id,
        cotizacion,
        emitir,
      }),
    );
  }, [cotizacion]);

  const handleClickStart = () => {
    if (cotizacion.solicitud.tipo == 'vehiculo') {
      history.push(`/inspeccion/fotos/${id}`);
    }else{
      history.push(`/inspeccion/fotomoto/${id}`);
    }
  };

  return (
    <>
      <Container>
        <Title>
          Inspección
          {' '}
          <TitleMod>Digital</TitleMod>
        </Title>

        {loading ? (
          <Loader
            type="Oval"
            color="#213c83"
            width={50}
            height={50}
            timeout={10000}
          />
        ) : cotizacion.status ? (
          <>
            
            {cotizacion.solicitud.okm ? 
              <Content>
              <TextContent><p>Completá el proceso de emisión de tu póliza 100% online de tu auto 0km.</p>
                <br/>
              <p>Necesitamos que por favor nos adjuntes el Certificado de No Rodamiento para poder finalizar con la inspección.</p></TextContent>
              
              
              <BtnContinue onClick={handleClickStart}>
                Empezar
              </BtnContinue>
              </Content>
            
            : isMobile ? 
              <Content>
              <TextContent><p>Completá el proceso de emisión de tu póliza, ¡hacé tu inspección 100% online!</p>
                <br/>
              { cotizacion.solicitud.tipo === 'vehiculo' ? (
              <p>La inspección digital de tu vehículo deberá efectuarse en horario diurno con el auto completamente seco y limpio. Necesitamos que tomes 6 fotos, por favor seguí las indicaciones a continuación.</p>
              ):(
                <p>La inspección digital de tu vehículo deberá efectuarse en horario diurno con la moto completamente seca y limpia. Necesitamos que tomes 1 foto, por favor seguí las indicaciones a continuación.</p>
              )}
              </TextContent>

              <BtnContinue onClick={handleClickStart}>
                Empezar
              </BtnContinue>
              </Content>
            :
              <Content>
                <TextContent>Ocurrió un error o no está accediendo desde un smartphone.</TextContent>
              </Content>    
            }
          </>
        ) : (
          <Content>
            <TextContent>Ocurrió un error o no está accediendo desde un smartphone.</TextContent>
          </Content>
        )}
      </Container>
    </>
  );
};

export default Inspeccion;

/*
 *
 *
 * STYLES
 *
 *
*/

const Container = styled.div`
  padding: 30px 15px;
  background: var(--blanco);
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.p`
  margin-bottom: 32px;
  font-weight: 300;
  color: var(--azul);
  text-align: center;
  font-size: 28px;
`;

const TitleMod = styled.span`
  color: var(--verde);
`;

const Content = styled.div`
  text-align: center;
  margin-bottom: 32px;
`;

const TextContent = styled.p`
  color: var(--azul);
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 20px;
`;

const BtnContinue = styled.div`
  cursor: pointer;
  background: var(--azul);
  border: none;
  padding: 10px 20px;
  text-align: center;
  display: block;
  width: 100%;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
`;
