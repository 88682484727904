const tipo_pago = (state = '', action) => {
    switch (action.type) {
      case 'SET_TIPO_PAGO': {
        state = action.payload;
        return state;
      }
      default:
        return state;
    }
  };
  
  export default tipo_pago;