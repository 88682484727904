import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from "styled-components";
import Coverit from '../../assets/logo-coverit.png';
import SolicitarAsistencia from '../SolicitarAsistencia';





const InspeccionFinalizadaError = () => {

  const [asistencia, setAsistencia] = useState(false);

  const handleAsistencia = (value) => {
    setAsistencia(value);
  };


  return (
    <>
      <Container>
        <LogoCoveritImg
        src={Coverit}
        alt="Coverit"
        />
        <TextContent>
          Tuvimos un problema al recibir tu inspección.
        </TextContent>
        <BtnAsistencia onClick={() => handleAsistencia(true)}>
        Quiero que me llamen
        </BtnAsistencia>
      </Container>
      {asistencia && (
          <SolicitarAsistencia handleAsistencia={handleAsistencia} />
          )}
    </>
  )
}

export default InspeccionFinalizadaError;

/**
 * STYLES
 */

const Container = styled.div`
padding: 30px 15px;
background: var(--verde);
min-height: calc(100vh);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

const Title = styled.p`
margin-bottom: 32px;
font-weight: 300;
color: var(--azul);
text-align: center;
font-size: 28px;
`;

const TextContent = styled.p`
  color: #fff;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 20px;
`;

const Link = styled.a`
  text-align: center;
  margin-top: 15px;
`

const Image = styled.img`
  width: 130px;
  left: calc(50% - 65px);
  top: 34px;
  @media screen and (min-width: 1000px) {
    position: static;
    width: 178px;
    margin-top: 30px;
  }
`;

const BtnContinue = styled.div`
  background: var(--verde);
  border: none;
  padding: 10px 20px;

  &.disabled {
    background: var(--verde-disabled);
  }

  & a {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
  }

  &.disabled a {
    pointer-events: none;
  }
`;

const Btns = styled.div`
  display: flex;
  justify-content: center;
`;

const LogoCoveritImg = styled.img`
  padding: 2%;
  border-radius: 5px;
  background-color: #fff;
  width: 30% !important;
`;

const BtnAsistencia = styled.button`
  cursor: pointer;
  color: var(--verde);
  border: none;
  border: 2px solid var(--verde);
  padding: 10px 20px;
  text-align: center;
  margin-top: 10px;
  background: #fff;
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;
`;