import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import imgPlaceholdUno from "../../assets/moto.jpg";
import {useAlert } from 'react-alert';
import Compressor from 'compressorjs';


const InspeccionFotos = () => {
  var alert = useAlert();
  const { id } = useParams();
  const history = useHistory();
  const inspeccion = useSelector((state) => state.inspeccion);
  const vehiculo = useSelector((state) => state.vehiculo);
  const vigencia = useSelector((state) => state.vigencia);
  const tarjeta = useSelector((state) => state.tarjeta);
  const coberturaSeleccionada = useSelector(
    (state) => state.coberturaSeleccionada
  );
  const cliente = useSelector((state) => state.cliente);
  const dataVehiculo = useSelector((state) => state.dataVehiculo);
  const sumaAsegurada = useSelector((state) => state.sumaAsegurada);
  const [loading, setLoading] = useState(false);
  const [previewSrcUno, setPreviewSrcUno] = useState("");
  const [currentFoto, setCurrentFoto] = useState(1);
  const foto1Ref = useRef(null);
  

  var [archivoUno, setArchivoUno] = useState(null);
  
  const [fotos] = useState([]);
  const [emitir, setEmitir] = useState(false);
  
  useEffect(() => {
    if (inspeccion.emitir) {
      setEmitir(true);
    } else {
      setEmitir(false);
    }
  }, []);

  const handleSiguiente = (e) => {
    e.preventDefault();
    if(fotos.includes(currentFoto)) setCurrentFoto(currentFoto + 1);
    else alert.error('Debe tomar la foto indicada');
  }

  const fileReader = (file) => {
      if(file !== undefined)  {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
        setPreviewSrcUno(reader.result);
        if(!fotos.includes(currentFoto)) fotos.push(currentFoto);
        }
      };
  }

  const handleChangeFoto = () => {
    setLoading(true);
    new Compressor(foto1Ref.current.files[0], {
        quality: 0.6,
        width: 1024,
        height: 768,
        maxWidth: 1024,
        maxHeight: 768,
        success(result) {
        setArchivoUno(result);
        },
        error(err){
        console.log(err.message);
        }});
    fileReader(foto1Ref.current.files[0]);
    setLoading(false);
  }

  const token = useSelector((state) => state.token);
  const BASE_URL = process.env.REACT_APP_API_URL;

    const handleClickFinish = (e) => {
    e.preventDefault();

    if(!fotos.includes(currentFoto)) {
      alert.error('Debe tomar la foto indicada');
      return;
    }
    

    const formDataInspeccion = new FormData();
    formDataInspeccion.append("cotizacion_id", id);
    formDataInspeccion.append("foto1", archivoUno);
        
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formDataInspeccion,
      redirect: "follow",
    };
    setLoading(true);
    fetch(`${BASE_URL}/subir-inspecciones`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result.status) {
        if(emitir) {
          fetch(
            `${BASE_URL}/solicitar-emision`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: new URLSearchParams({
                cotizacion_id: coberturaSeleccionada.id,
                tipo_documento: cliente.dniElegido,
                documento: cliente.dniValue,
                sexo: cliente.sexo,
                cuit: cliente.CUILT,
                situacion_afip: cliente.condicionIVA,
                iibb: cliente.condicionIIBB,
                patente: dataVehiculo.patente,
                chasis: dataVehiculo.chasis,
                motor: dataVehiculo.motor,
                anios_siniestros: 0,
                calle: cliente.calle,
                numero: cliente.nro,
                vigencia_desde: vigencia,
                numero_tarjeta: tarjeta.nroTarjeta,
                telefono: cliente.tel, 
                estado_civil: 1,
                forma_pago: tarjeta.idFormaPagoElegida
              }),
              redirect: "follow",
            }
          )
          .then((response) => response.json())
          .then((result) => {
            setLoading(false);
            if(result.status) history.push(`/inspeccion-finalizada/${id}`);
            else alert.error(result.message);
            //else history.push(`/inspeccion-efinalizada/${coberturaSeleccionada.id}`);
          },[emitir, coberturaSeleccionada, cliente, dataVehiculo, vigencia, tarjeta])
          .catch((error) => {
            console.log("error", error);
            setLoading(false);
          });
        } else {
          console.log('Fotos subidas sin emisión');
          history.push(`/inspeccion-finalizada/${id}`);
          setLoading(false);
        }
      } else {
        setLoading(false);
        alert.error(result.message);
        history.push(`/inspeccion-efinalizada/${id}`);
      }
    }, [emitir, coberturaSeleccionada, cliente, dataVehiculo, vigencia, tarjeta])
    .catch((error) => {
      console.log("error", error);
      setLoading(false);
    })
  };

  return (
    <>
      <Container>
        <Title>
          Inspección <TitleMod>Digital</TitleMod>
        </Title>
            <>
            <Form onSubmit={handleClickFinish}>
              <Content className={currentFoto === 1 ? "visible" : "hidden"}>
                <TextContent>Foto 1</TextContent>
                <TextContent>
                    Asegurate de que se vea la parte trasera completa y la patente.
                </TextContent>
                {previewSrcUno ? (
                  <Img src={previewSrcUno} />
                ) : (
                  <Img src={imgPlaceholdUno} className="placehold" />
                )}
                <BtnCamera>
                  {loading ? (
                    <Loader
                      type="Oval"
                      color="#213c83"
                      width={50}
                      height={50}
                    />
                  ) : (
                    <>
                      <InputCamera
                        type="file"
                        accept="image/*"
                        capture="camera"
                        id="foto1"
                        name="foto1"
                        ref={foto1Ref}
                        onChange={handleChangeFoto}
                      />
                      <FontAwesomeIcon icon={faCamera} />
                      Tomar foto
                    </>
                  )}
                </BtnCamera>
              </Content>

              <Btns>

                  <BtnBack onClick={() => setCurrentFoto(currentFoto - 1)}>
                    Volver
                  </BtnBack>

                  <BtnContinue type="submit">Finalizar</BtnContinue>
              </Btns>
            </Form>
          </>
      </Container>
    </>
  );
};

export default InspeccionFotos;

/*
 *
 *
 * STYLES
 *
 *
 */

const Container = styled.div`
  padding: 30px 15px;
  background: var(--blanco);
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.p`
  margin-bottom: 32px;
  font-weight: 300;
  color: var(--azul);
  text-align: center;
  font-size: 28px;
`;

const TitleMod = styled.span`
  color: var(--verde);
`;

const Content = styled.div`
  text-align: center;
  margin-bottom: 32px;

  &.visible {
    display: block;
  }

  &.hidden {
    display: none;
  }
`;

const TextContent = styled.p`
  color: var(--azul);
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 20px;
`;

const Btns = styled.div`
  display: flex;
  width: 300px;
  margin: 0 auto;
`;

const BtnBack = styled.div`
  background: none;
  border: 1px solid var(--verde);
  padding: 10px 20px;
  text-align: center;
  display: block;
  flex: 1;
  margin-right: 10px;
  color: var(--verde);
  text-transform: uppercase;
  font-size: 16px;

  & a {
    color: var(--verde);
    font-size: 16px;
    text-transform: uppercase;
  }
`;

const BtnContinue = styled.button`
  cursor: pointer;
  background: var(--azul);
  border: 1px solid var(--azul);
  padding: 10px 20px;
  text-align: center;
  display: block;
  flex: 1;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;

  &.disabled {
    background: var(--verde-disabled);
  }

  & a {
    color: var(--verde);
    font-size: 16px;
    text-transform: uppercase;
  }
`;

const Img = styled.img`
  width: 300px;
  height: 225px;
  object-fit: contain;

  &.placehold {
    opacity: 0.5;
  }
`;

const BtnCamera = styled.label`
  cursor: pointer;
  width: 300px;
  padding: 10px;
  border: 1px solid var(--verde);
  background: none;
  color: var(--verde);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  margin: 12px auto 0;

  &.disabled {
    background: var(--verde-disabled);
  }

  & svg {
    margin-right: 10px;
  }
`;

const InputCamera = styled.input`
  display: none;
`;

const Form = styled.form`
  display: block;
`;
