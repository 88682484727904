import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import SolicitarAsistencia from '../SolicitarAsistencia';
import ProgressBar from '../ProgressBar';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import {useAlert } from 'react-alert'

import SwissMedical from '../../assets/sm_seguros.png';
import Coverit from '../../assets/logo-coverit.png';

const TyC = () => {
  var alert = useAlert();
  const history = useHistory();
  const { id, prev } = useParams();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const vehiculo = useSelector((state) => state.vehiculo);
  const asegurar = useSelector((state) => state.asegurar);
  const vigencia = useSelector((state) => state.vigencia);
  const tarjeta = useSelector((state) => state.tarjeta);
  const tipo_pago = useSelector((state) => state.tipo_pago);
  const cobertura = useSelector((state) => state.Cobertura);
  const sumaAsegurada = useSelector((state) => state.sumaAsegurada);
  const franquicia = useSelector((state) => state.franquicia);
  const coberturaSeleccionada = useSelector(
    (state) => state.coberturaSeleccionada,
  );
  const cliente = useSelector((state) => state.cliente);
  const [errorEmision, setErrorEmision] = useState(false);
  const [asistencia, setAsistencia] = useState(false);


  const dataVehiculo = useSelector(
    (state) => state.dataVehiculo,
  );

  const medioPago = useSelector(
    (state) => state.forma_pago,
  );

  const handleContinue = () => {
    if (tipo_pago === 'credito') {
        history.push('/tarjeta-de-credito/');
      } else if (tipo_pago === 'debito') {
        history.push('/debito-automatico/');
      }
  };

  const handleContinueInspeccion = () => {
    history.push('/11/');
  };


  const handleAsistencia = (value) => {
    setAsistencia(value);
  };

  return (
    <>
          <CointainerAzul>
            <>
              <Title>
                Antes de continuar, por favor verificá los datos de tu contratación:
              </Title>
              <DivCentrado>
                <LogoImg src={SwissMedical} alt="Swiss Medical Seguros" />
                <LogoImg src={Coverit} alt="Coverit" />
              </DivCentrado>
              <Text>
                <b>Datos personales</b>
              </Text>
              <Text>
                {'Apellido y Nombre: '}{cliente.apellido}{', '}{cliente.nombre}<br />
                {'Documento Nº: '}{cliente.dniValue}
              </Text>
              <Text>
                <b>Datos del vehículo</b>
              </Text>
              <Text>
                {'Marca: '}{asegurar.marcaElegida}<br />
                {'Modelo: '}{asegurar.modeloElegido}<br />
                {'Versión: '}{asegurar.versionElegida}<br />
                {'Año: '}{asegurar.anioElegido}<br />
                {'Dominio: '}{dataVehiculo.patente}<br />
                {'Suma Asegurada: $'}{new Intl.NumberFormat('de-DE').format(parseFloat(sumaAsegurada) + parseFloat(asegurar.gncValue))}
              </Text>
              <Text>
                <b>Tipo de Cobertura:</b>
              </Text>
              {coberturaSeleccionada.franquicia ? (
              <Text>
                  {coberturaSeleccionada.categoria}{' ('}{coberturaSeleccionada.nombre}{')'}
                  <br/>
                  {'Franquicia: $'}{coberturaSeleccionada.franquicia}
              </Text> ) : (
              <Text>
              {coberturaSeleccionada.categoria}{' ('}{coberturaSeleccionada.nombre}{')'}
              </Text>
              )}
              <Text>
              <b>Detalle de Cobertura:</b>              
              </Text>
              <DetalleDescripcion>{parse(coberturaSeleccionada.descripcion)}</DetalleDescripcion>
              <Btns>
                  <BtnContinue
                     onClick={handleContinueInspeccion}
                    >
                      Cancelar
                  </BtnContinue>
                  <BtnContinue
                    onClick={handleContinue}>
                    Aceptar
                   </BtnContinue>


              </Btns>
              <BtnAsistencia onClick={() => handleAsistencia(true)}>
                Quiero que me llamen
              </BtnAsistencia>
            </>
          </CointainerAzul>

          {asistencia && (
            <SolicitarAsistencia handleAsistencia={handleAsistencia} />
          )}
        </>
      )};

export default TyC;

/*
 *
 *
 * STYLES
 *
 *
 */

const CointainerAzul = styled.div`
  min-height: calc(100vh);
  padding: 30px 15px;
  background: var(--blanco);
  position: relative;

  & li.slide {
    background: var(--blanco);
  }
`;

const Title = styled.p`
  margin: 10px 0;
  font-weight: 300;
  color: var(--verde);
  text-align: left;
  font-size: 20px;
`;

const TitleMod = styled.span`
  color: var(--azul);
  display: block;
`;

const DivCentrado = styled.div`
  text-align: center;
`;

const Text = styled.p`
  color: var(--azul);
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 10px;
  font-weight: 300;
`;

const TextArea = styled.textarea`
  color: var(--azul);
  width: 100%;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 16px;
  font-weight: 300;
  border-color: var(--verde);
  resize: none;
`;

const DetalleDescripcion = styled.p`
  font-size: 14px;
  color: var(--azul);
  height: 120px;
  overflow-y: auto;
  border: 1px solid var(--verde);
  margin-bottom: 5px;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--azul);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #e1efef;
    border-radius: 20px;
  }
`;

const CoberturaContainer = styled.div`
  margin: 0 0 24px;
  border: 1px solid var(--gris);
  padding: 10px 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Cobertura = styled.div`
  text-align: center;
`;

const CoberturaNombre = styled.p`
  color: var(--azul);
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 5px;
`;

const CoberturaBtnInfo = styled.button`
  border: none;
  background: #909ec2;
  color: #fff;
  display: block;
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
`;


const Btns = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & p {
    font-weight: 300;
    color: var(--verde);
    font-size: 14px;
    width: 100%;
    margin-bottom: 12px;
  }
`;


const BtnContinue = styled.div`
  cursor: pointer;
  background: var(--verde);
  border: none;
  padding: 10px 20px;
  text-align: center;
  border: 2px solid var(--verde);
  display: block;
  width: 48%;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;

  & svg {
    margin-right: 10px;
  }
`;

const BtnAsistencia = styled.button`
  cursor: pointer;
  color: var(--verde);
  border: none;
  border: 2px solid var(--verde);
  padding: 10px 20px;
  text-align: center;
  margin-top: 24px;
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 32px;
  background: none;
`;
const LogoImg = styled.img`
  text-align: center;
  width: 80px;
  margin: 10px;
`;