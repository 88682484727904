import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import SolicitarAsistencia from '../SolicitarAsistencia';
import ProgressBar from '../ProgressBar';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import {useAlert } from 'react-alert'

import SwissMedical from '../../assets/sm_seguros.png';

const PreInspeccion = () => {
  var alert = useAlert();
  const history = useHistory();
  const { id, prev } = useParams();

  const token = useSelector((state) => state.token);
  const BASE_URL = process.env.REACT_APP_API_URL;

  const vehiculo = useSelector((state) => state.vehiculo);
  const vigencia = useSelector((state) => state.vigencia);
  const tarjeta = useSelector((state) => state.tarjeta);
  const coberturaSeleccionada = useSelector(
    (state) => state.coberturaSeleccionada,
  );
  const cliente = useSelector((state) => state.cliente);
  const [errorEmision, setErrorEmision] = useState(false);
  const [asistencia, setAsistencia] = useState(false);
  const [loading, setLoading] = useState(false);

  const dataVehiculo = useSelector(
    (state) => state.dataVehiculo,
  );

  const medioPago = useSelector(
    (state) => state.forma_pago,
  );

  const handleContinue = () => {
    setLoading(true);
    /*
    setErrorTarjeta(false);
    
    fetch(`${BASE_URL}/validar-tarjeta?card_number=${nroTarjeta}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow',
    })
    
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          setErrorTarjeta(false);
          */
          fetch(
            `${BASE_URL}/solicitar-emision`,
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              redirect: 'follow',
              body: new URLSearchParams({
                cotizacion_id: coberturaSeleccionada.id,
                tipo_documento: cliente.dniElegido,
                documento: cliente.dniValue,
                sexo: cliente.sexo,
                cuit: cliente.CUILT,
                situacion_afip: cliente.condicionIVA,
                iibb: cliente.condicionIIBB,
                patente: dataVehiculo.patente,
                chasis: dataVehiculo.chasis,
                motor: dataVehiculo.motor,
                anios_siniestros: 0,
                calle: cliente.calle,
                numero: cliente.nro,
                vigencia_desde: vigencia,
                numero_tarjeta: tarjeta.nroTarjeta,
                telefono: cliente.tel,
                estado_civil: 1,
                forma_pago: tarjeta.idFormaPagoElegida
              }),
            },
          )
            .then((response) => response.json())
            .then((result) => {
              setLoading(false);
              if (result.status) {
                history.push(`/emision-finalizada/${coberturaSeleccionada.id}`);
              } else {
                setErrorEmision(true);
                alert.error(result.message)
              }
            })
            .catch((error) => {
              alert.error('No se pudo emitir la poliza por favos solicite asistencia');
              console.log('error', error)
            });
            /*
        } else {
          setLoading(false);
          setErrorTarjeta(true);
          alert.error(result.message);
        }
      })
      .catch((error) => console.log('error', error));*/
  };




  const handleContinueInspeccion = () => {
    history.push(`/inspeccion/${coberturaSeleccionada.id}/e`);
  };


  const handleAsistencia = (value) => {
    setAsistencia(value);
  };

  return (
    <>
      {!vehiculo ? (
        <Redirect to="/" />
      ) : (
        <>
          <CointainerAzul>
            <>
              <ProgressBar percentaje="p6" value="6 de 6" />

              <Title>
                {cliente.nombre}
                {', '}
              </Title>
              <Text>
                Para obtener tu póliza con la cobertura seleccionada deberás realizar la inspección digital dentro de 
                las próximas 48 horas, en horario diurno con el vehículo completamente seco y limpio.
              </Text>

              <Text>
                ¿Querés realizar la inspección ahora o continuar más tarde?
              </Text>

              <Btns>
                  <BtnContinue
                     onClick={handleContinueInspeccion}
                    >
                      Realizar ahora
                  </BtnContinue>
                  <BtnContinue
                    onClick={handleContinue}>
                     {loading ? (
                      <>
                        {'Finalizando '}
                        <Loader
                          type="Oval"
                          color="#ffffff"
                          width={30}
                          height={20}
                        />
                      </>
                    ) : (
                      'Más tarde'
                    )}
                   </BtnContinue>


              </Btns>
              <BtnAsistencia onClick={() => handleAsistencia(true)}>
                Quiero que me llamen
              </BtnAsistencia>
            </>
          </CointainerAzul>

          {asistencia && (
            <SolicitarAsistencia handleAsistencia={handleAsistencia} />
          )}
        </>
      )}
    </>
  );
};

export default PreInspeccion;

/*
 *
 *
 * STYLES
 *
 *
 */

const CointainerAzul = styled.div`
  min-height: calc(100vh);
  padding: 30px 15px;
  background: var(--blanco);
  position: relative;

  & li.slide {
    background: var(--blanco);
  }
`;

const Title = styled.p`
  margin: 32px 0;
  font-weight: 300;
  color: var(--verde);
  text-align: left;
  font-size: 28px;
`;

const TitleMod = styled.span`
  color: var(--azul);
  display: block;
`;

const Text = styled.p`
  color: var(--azul);
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 16px;
  font-weight: 300;
`;

const CoberturaContainer = styled.div`
  margin: 0 0 24px;
  border: 1px solid var(--gris);
  padding: 10px 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Cobertura = styled.div`
  text-align: center;
`;

const CoberturaNombre = styled.p`
  color: var(--azul);
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 5px;
`;

const CoberturaBtnInfo = styled.button`
  border: none;
  background: #909ec2;
  color: #fff;
  display: block;
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
`;


const Btns = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & p {
    font-weight: 300;
    color: var(--verde);
    font-size: 14px;
    width: 100%;
    margin-bottom: 12px;
  }
`;


const BtnContinue = styled.div`
  cursor: pointer;
  background: var(--verde);
  border: none;
  padding: 10px 20px;
  text-align: center;
  border: 2px solid var(--verde);
  display: block;
  width: 48%;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;

  & svg {
    margin-right: 10px;
  }
`;

const BtnAsistencia = styled.button`
  cursor: pointer;
  color: var(--verde);
  border: none;
  border: 2px solid var(--verde);
  padding: 10px 20px;
  text-align: center;
  margin-top: 24px;
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 32px;
  background: none;
`;