import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import parse from 'html-react-parser';
import ProgressBar from '../ProgressBar';
import SolicitarAsistencia from '../SolicitarAsistencia';
import { setCoberturaSeleccionada } from '../../actions';

import SwissMedical from '../../assets/sm_seguros.png';

const Paso6 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const vehiculo = useSelector((state) => state.vehiculo);
  const gnc = useSelector((state) => state.gnc);
  const cliente = useSelector((state) => state.cliente);
  const asegurar = useSelector((state) => state.asegurar);
  const sumaAsegurada = useSelector((state) => state.sumaAsegurada);
  const cp = useSelector((state) => state.cp);
  const solicitud = useSelector((state) => state.solicitud);
  const coberturas = useSelector((state) => state.coberturas);
  const categorias = useSelector((state) => state.categorias);

  const [cots, setCots] = useState([]);
  const [detalle, setDetalle] = useState(false);
  const [asistencia, setAsistencia] = useState(false);
  const [coberturaElegida, setCoberturaElegida] = useState(null);
  const [categoriaElegida, setCategoriaElegida] = useState(null);
  const [
    coberturaElegidaDescripcion,
    setCoberturaElegidaDescripcion,
  ] = useState(null);
  const [franquicia, setFranquicia] = useState(null);
  const [item, setItem] = useState(null);

  useEffect(() => {
    setCots(solicitud.solicitud.cotizaciones.sort(function (a, b) { console.log(a.orden, b.orden); return a.orden >= b.orden ? 1 : -1 }) || []);
  }, []);

  useEffect(() => {
    console.log(vehiculo);
  })
  
  const handleDetalle = (value, categoria, index) => {
    setDetalle(!detalle);
    if (!coberturaElegida) {
      setItem(index);
      setCategoriaElegida(categoria);
      setCoberturaElegida(value.nombre);
      setCoberturaElegidaDescripcion(value.descripcion);
      setFranquicia(value.franquicia);
    } else {
      setCoberturaElegida(null);
      setCoberturaElegidaDescripcion(null);
      setFranquicia(null);
    }
  };

  const handleAsistencia = (value) => {
    setAsistencia(value);
  };

  const handleSelectCarouselItem = (selectedIndex, e) => {
    setIndex(selectedIndex);
    console.log(cots.length);
  }

  const handleContratacion = () => {

    let cotizacion = cots[index];
    let categoria = categorias.find(ca => ca.id == cotizacion.categoria_cobertura);
    let cobertura = coberturas.find(co => co.codigo == cotizacion.cobertura_id); 

    dispatch(setCoberturaSeleccionada({
      id: cotizacion.id,
      nombre: cobertura.nombre,
      descripcion: cobertura.descripcion,
      cuota: cotizacion.cuota,
      categoria: categoria.nombre,
      franquicia: cotizacion.franquicia,
    }));
    history.push('/7/');
  };

  return (
    <>
      {!vehiculo ? (
        <Redirect to="/" />
      ) : (
        <Container>
          {cots && (
            <>
              <ProgressBar percentaje="p6" value="6 de 6" />

              {!detalle ? (
                <>
                  <Title>
                    <TitleMod>{cliente.nombre}</TitleMod>
                    {', '}
                    estas son las mejores propuestas para tu cobertura.
                  </Title>

                  <DataList>
                    <DataListItem>{asegurar.marcaElegida}</DataListItem>
                    <DataListItem>{asegurar.modeloElegido}</DataListItem>
                    <DataListItem>{asegurar.anioElegido}</DataListItem>
                    <DataListItem>
                      CP
                      {cp}
                    </DataListItem>
                  </DataList>
                  {vehiculo === 'auto' ? (
                  <SumaAseguradaContainer>
                    <SumaAseguradaValue>
                      $
                      {' '}
                      {new Intl.NumberFormat('de-DE').format(sumaAsegurada)}
                    </SumaAseguradaValue>
                    <SumaAseguradaText>Suma asegurada</SumaAseguradaText>
                      {asegurar.gnc == '1' ? (
                        <>
                        <MasText>+</MasText>
                        <SumaAseguradaValue>
                        $
                        {' '}
                        {new Intl.NumberFormat('de-DE').format(asegurar.gncValue)}
                        </SumaAseguradaValue>
                        <SumaAseguradaText>GNC</SumaAseguradaText>
                        </>
                      ) : null}
                    {cots.length > 0 && (
                      <SumaAseguradaImg
                        src={SwissMedical}
                        alt="Swiss Medical Seguros"
                      />
                    )}
                  </SumaAseguradaContainer>
                  ):(
                    cots.length > 0 && (
                      <SumaAseguradaContainer>
                        <SumaAseguradaImg
                          src={SwissMedical}
                          alt="Swiss Medical Seguros"
                        />
                      </SumaAseguradaContainer>
                    )
                  )}

                  <CotizacionesContainer id="cotizaciones_container">
                    {cots.length > 0 ? (
                      <>
                        <Carousel
                          autoPlay={false}
                          showThumbs={false}
                          showStatus={false}
                          dynamicHeight={false}
                          showArrows={cots.length > 1}
                          showIndicators={cots.length > 1}
                          infiniteLoop={cots.length > 1}
                          selectedItem={'' || item}
                          activeIndex={index}
                          onChange={handleSelectCarouselItem}
                        >
                          {cots.map((cotizacion, index) => {
                            
                            let categoria = categorias.find(ca => ca.id == cotizacion.categoria_cobertura);
                            let cobertura = coberturas.find(co => co.codigo == cotizacion.cobertura_id); 
                            
                            return (
                              <CotizacionesItem
                                key={cotizacion.id}
                                id={cotizacion.id}
                              >
                                <CotizacionesItemCobertura>
                                  {categoria.nombre}
                                  {' ('}
                                  {cobertura && cobertura.nombre ? cobertura.nombre : ''}
                                  {')'}
                                </CotizacionesItemCobertura>
                                <CotizacionesItemMas onClick={() => handleDetalle(cobertura, categoria.nombre, index)}>
                                  <CotizacionesItemVerDetalle>
                                    Ver detalle&nbsp;&nbsp;
                                  </CotizacionesItemVerDetalle>
                                  <CotizacionItemVerDetalleMas>
                                      +
                                  </CotizacionItemVerDetalleMas>
                                </CotizacionesItemMas>
                                <CotizacionesItemCuota>
                                  $
                                  {' '}
                                  <span>{cotizacion.cuota}</span>
                                </CotizacionesItemCuota>
                                <CotizacionesItemAclaracionCuota>
                                  Valor cuota
                                  {' '}
                                  <span>(Mensual)</span>
                                </CotizacionesItemAclaracionCuota>
                                <CotizacionesItemDescuentoContainer>
                                  <CotizacionesItemDescuentoTachado>
                                    $
                                    {(cotizacion.cuota * 1.3333335).toFixed(2)}
                                  </CotizacionesItemDescuentoTachado>
                                  <CotizacionesItemDescuentoOff>
                                    25% OFF
                                  </CotizacionesItemDescuentoOff>
                                </CotizacionesItemDescuentoContainer>
                                {cotizacion.franquicia ? (
                                  <Franquicia>
                                    Franquicia: $
                                    {cotizacion.franquicia}
                                  </Franquicia>
                                ) : null}
                              </CotizacionesItem>
                            );
                          })}
                        </Carousel>
                        <CotizacionesItemCobertura>
                          Podes contratar tu poliza 100% online o si preferís te contactamos.
                        </CotizacionesItemCobertura>
                        <BtnContinue onClick={handleContratacion}>
                          Contratar online
                        </BtnContinue>
                        <BtnAsistencia onClick={() => handleAsistencia(true)}>
                          Quiero que me llamen
                        </BtnAsistencia>
                      </>
                    ) : (
                      <>
                        <NoTenemos>
                          Cotización disponible únicamente por teléfono
                        </NoTenemos>
                        <BtnAsistencia onClick={() => handleAsistencia(true)}>
                          Quiero que me llamen
                        </BtnAsistencia>
                      </>
                    )}
                  </CotizacionesContainer>

                  {asistencia && (
                    <SolicitarAsistencia handleAsistencia={handleAsistencia} />
                  )}
                </>
              ) : (
                <DetalleContainer>
                  <DetalleHeader>
                    <DetalleVolver>
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        onClick={() => handleDetalle(null)}
                      />
                    </DetalleVolver>
                    {}
                    <DetalleImg
                      src={SwissMedical}
                      alt="Swiss Medical Seguros"
                    />
                  </DetalleHeader>
                  <DetalleCobertura>
                    <CoberturaElegida>
                      {categoriaElegida}
                      <span>
                        (
                        {coberturaElegida}
                        )
                      </span>
                    </CoberturaElegida>
                    <div>
                      <DetalleSumaAsegurada>
                        $
                        {' '}
                        {new Intl.NumberFormat('de-DE').format(parseFloat(sumaAsegurada) + parseFloat(asegurar.gncValue))}
                      </DetalleSumaAsegurada>
                      <DetalleSumaAseguradaText>
                        Suma asegurada
                      </DetalleSumaAseguradaText>
                      <PolizaAnual>
                        Póliza anual con refacturación mensual
                      </PolizaAnual>
                    </div>
                  </DetalleCobertura>
                  {franquicia ? (
                    <Franquicia>
                      Franquicia: $
                      {franquicia}
                    </Franquicia>
                  ) : null}
                  <DetalleDescripcion>
                    {parse(coberturaElegidaDescripcion)}
                  </DetalleDescripcion>
                  <BtnContinue onClick={handleContratacion}>
                    Ir a contratación online
                  </BtnContinue>
                </DetalleContainer>
              )}
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default Paso6;

/*
 *
 *
 * STYLES
 *
 *
 */

const Container = styled.div`
  padding: 30px 15px;
`;

const Title = styled.p`
  margin-top: 24px;
  font-weight: 300;
  color: var(--azul);
  text-align: center;
  font-size: 20px;
`;

const TitleMod = styled.span`
  color: var(--verde);
`;

const DataList = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (min-width: 1200px) {
    width: 50%;
    margin: 20px auto 0;
  }
`;

const DataListItem = styled.li`
  flex: 1;
  margin: 0 5px;
  padding: 10px 5px;
  border: 1px solid var(--azul);
  text-transform: uppercase;
  color: var(--azul);
  text-align: center;
  border-radius: 5px;
`;

const SumaAseguradaContainer = styled.div`
  padding: 16px 0;
  text-align: center;
`;

const SumaAseguradaValue = styled.p`
  color: var(--verde);
  font-size: 20px;
  font-weight: 300;
`;

const SumaAseguradaText = styled.p`
  color: var(--verde);
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
`;

const MasText = styled.p`
  color: var(--verde);
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
`;

const SumaAseguradaImg = styled.img`
  text-align: center;
  padding-top: 10px;
  width: 55%;
`;

const CotizacionesContainer = styled.div`
  min-height: 200px;

  @media (min-width: 1200px) {
    width: 20%;
    margin: auto;
  }

  & .control-dots .dot {
    opacity: 1;
    border: 1px solid var(--verde);
    background: none;
    box-shadow: none;
    width: 10px;
    height: 10px;
  }

  & .control-dots .dot.selected {
    background: var(--verde);
  }

  & .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 1;
    padding: 0;
    background: var(--verde);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    top: calc(50% - 15px);
  }
`;

const CotizacionesItem = styled.div`
  min-height: 200px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CotizacionesItemCobertura = styled.p`
  color: var(--azul);
  font-size: 16px;
  font-weight: 300;
`;

const CotizacionesItemVerDetalle = styled.div`
  color: var(--gris);
  float: left;
  font-size: 12px;
  height: 20px;
  font-weight: bold;
  margin: 5px 0;
`;

const CotizacionesItemMas = styled.p`
  cursor: pointer;
`;

const CotizacionItemVerDetalleMas = styled.div`
  background: var(--verde);
  border-radius: 50%;
  float: right;
  width: 20px;
  height: 20px;
  font-size: 16px;
`;

const CotizacionesItemCuota = styled.p`
  color: var(--verde);
  font-weight: 300;
  font-size: 24px;

  & span {
    font-weight: 500;
    font-size: 28px;
  }
`;

const CotizacionesItemAclaracionCuota = styled.p`
  color: var(--azul);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  margin: 5px 0;

  & span {
    font-weight: 300;
  }
`;

const CotizacionesItemDescuentoContainer = styled.div`
  display: flex;
`;

const CotizacionesItemDescuentoTachado = styled.p`
  color: var(--verde);
  margin-right: 12px;
  font-size: 16px;
  font-weight: 400;
  text-decoration: line-through;
`;

const CotizacionesItemDescuentoOff = styled.p`
  background: var(--verde);
  color: #fff;
  padding: 2px 10px;
  font-size: 14px;
  border-radius: 10px;
  font-weight: 400;
`;

const BtnContinue = styled.div`
  cursor: pointer;
  background: var(--verde);
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  text-align: center;
  margin-top: 12px;
  border: 2px solid var(--verde);
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;

  & a {
    color: #fff;
  }
`;

const BtnAsistencia = styled.button`
  cursor: pointer;
  color: var(--verde);
  border: none;
  border: 2px solid var(--verde);
  padding: 10px 20px;
  text-align: center;
  margin-top: 10px;
  background: #fff;
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;
`;

const DetalleContainer = styled.div`
  display: block;
  width: 100%;
  border: 1px solid var(--verde);
  border-radius: 30px;
  position: relative;
  margin-top: 24px;
  padding: 24px;
`;

const DetalleHeader = styled.div`
  display: flex;
  justify-content: center;
`;

const DetalleVolver = styled.div`
  position: absolute;
  left: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--verde);
  border: 2px solid var(--verde);
  border-radius: 50%;

  & svg {
    color: #fff;
  }
`;

const DetalleImg = styled.img`
  width: 100px;
`;

const DetalleCobertura = styled.div`
  margin: 32px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CoberturaElegida = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: var(--azul);
  text-transform: uppercase;

  & span {
    display: block;
    font-weight: 400;
  }
`;

const DetalleSumaAsegurada = styled.p`
  color: var(--azul);
  text-align: center;
  font-weight: 300;
  font-size: 20px;
`;

const DetalleSumaAseguradaText = styled.p`
  color: var(--azul);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
  text-align: center;
`;

const PolizaAnual = styled.p`
  color: var(--azul);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
  text-align: center;
  margin-top: 10px;
`;

const DetalleDescripcion = styled.p`
  font-size: 14px;
  color: var(--azul);
  height: 200px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--azul);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #e1efef;
    border-radius: 20px;
  }
`;

const NoTenemos = styled.p`
  text-align: center;
  margin-bottom: 24px;
  font-size: 18px;
  color: var(--azul);
`;

const Franquicia = styled.p`
  color: var(--azul);
  font-weight: 600;
  text-align: center;
  margin: 0 0 10px;
`;
