import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import SolicitarAsistencia from '../SolicitarAsistencia';
import ProgressBar from '../ProgressBar';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import SwissMedical from '../../assets/sm_seguros.png';
import { isMobile } from 'react-device-detect';

const Credito = () => {
  const history = useHistory();
  const { id, prev } = useParams();

  const token = useSelector((state) => state.token);
  const BASE_URL = process.env.REACT_APP_API_URL;

  const vehiculo = useSelector((state) => state.vehiculo);
  const coberturaSeleccionada = useSelector(
    (state) => state.coberturaSeleccionada,
  );
  const cliente = useSelector((state) => state.cliente);

  const [asistencia, setAsistencia] = useState(false);

  const handleGetArchivo = () => {

    fetch(`${BASE_URL}/obtener-certificado?cotizacion_id=${id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow',
      fileCache: true,
      dataType: 'blob'
    })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `FileName.pdf`,
      );
  
      // Append to html link element page
      document.body.appendChild(link);
  
      // Start download
      link.click();
  
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
    .catch((error) => console.log('error', error));
  }

  const handleAsistencia = (value) => {
    setAsistencia(value);
  };

  const handleContinue = () => {
    history.push(`/inspeccion/${id}/12`);
  };

  return (
    <>
      {!vehiculo ? (
        <Redirect to="/" />
      ) : (
        <>
          <CointainerAzul>
            <>
              <ProgressBar percentaje="p6" value="6 de 6" />

              <Title>
                {cliente.nombre}
                {', '}
                <TitleMod>¡bienvenido a Coverit!</TitleMod>
              </Title>
              <Text>
                Ya podés descargar y guardar tu cobertura de Responsabilidad Civil provisoria.
              </Text>
              <Text>
                Para obtener tu póliza con la cobertura seleccionada deberás realizar la inspección digital dentro de 
                las próximas 48 horas, en horario diurno con el vehículo completamente seco y limpio.
              </Text>
              <Text>
                En unos minutos recibirás un SMS con el enlace para acceder desde tu celular.
              </Text>
              <CoberturaContainer>
                <LogoImg src={SwissMedical} alt="Swiss Medical Seguros" />
                <Cobertura>
                  <CoberturaNombre>{coberturaSeleccionada.nombre}</CoberturaNombre>
                </Cobertura>
              </CoberturaContainer>
              <BtnContinue onClick={handleGetArchivo}>
                <FontAwesomeIcon icon={faDownload} />
                  Descargar certificado provisorio
              </BtnContinue>             
              <BtnAsistencia onClick={() => handleAsistencia(true)}>
                Quiero que me llamen
              </BtnAsistencia>
            </>
          </CointainerAzul>

          {asistencia && (
            <SolicitarAsistencia handleAsistencia={handleAsistencia} />
          )}
        </>
      )}
    </>
  );
};

export default Credito;

/*
 *
 *
 * STYLES
 *
 *
 */

const CointainerAzul = styled.div`
  min-height: calc(100vh);
  padding: 30px 15px;
  background: var(--blanco);
  position: relative;

  & li.slide {
    background: var(--blanco);
  }
`;

const Title = styled.p`
  margin: 32px 0;
  font-weight: 300;
  color: var(--verde);
  text-align: left;
  font-size: 28px;
`;

const TitleMod = styled.span`
  color: var(--azul);
  display: block;
`;

const Text = styled.p`
  color: var(--azul);
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 16px;
  font-weight: 300;
`;

const CoberturaContainer = styled.div`
  margin: 0 0 24px;
  border: 1px solid var(--gris);
  padding: 10px 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Cobertura = styled.div`
  text-align: center;
`;

const CoberturaNombre = styled.p`
  color: var(--azul);
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 5px;
`;

const CoberturaBtnInfo = styled.button`
  border: none;
  background: #909ec2;
  color: #fff;
  display: block;
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
`;


const BtnContinue = styled.div`
  cursor: pointer;
  background: var(--verde);
  border: none;
  padding: 10px 20px;
  text-align: center;
  border: 2px solid var(--verde);
  display: block;
  width: 100%;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;

  & svg {
    margin-right: 10px;
  }
`;

const BtnAsistencia = styled.button`
  cursor: pointer;
  color: var(--verde);
  border: none;
  border: 2px solid var(--verde);
  padding: 10px 20px;
  text-align: center;
  margin-top: 24px;
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 32px;
  background: none;
`;

const LogoImg = styled.img`
  width: 40%;
`;
